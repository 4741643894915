<template lang="pug">
.notFound
  .title Oops!
  .tv-block
    img(src="../assets/tv.svg")
    .tv-text
      p.large 404
      p.little Page Not Found
  .text 在這裡找不到符合條件的相關內容，建議您回到『 酷東西 』首頁。
  .back
    router-link(to="/") 回首頁
</template>
<style lang="scss" scoped>
.notFound{
  display: flex;
  // height: calc(100vh - 184px);
  // height: 100vh;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 130px 30px 90px 30px;
  width: 100%;
  .back{
    margin-top: 40px;
    position: relative;
    background-color: rgb(29, 29, 29);
    // transform: translate(3px, 3px);
    width: 130px;
    height: 40px;
    border-radius: 8px;
    a{
      text-decoration: none;
      background-color: rgb(29, 29, 29);
      color: rgb(60, 255, 96);
      padding: 4px 15px;
      font-size: 18px;
      letter-spacing: 4px;
      transition-duration: .5s;
      position: absolute;
      width: 100%;
      top: 0px;
      left: 0px;
      border: solid 3px rgb(60, 255, 96);
      transform: translate(0px, -10px);
      text-align: center;
      border-radius: 8px;
      &:hover{
        transform: translate(0px, 0px);
        color: rgb(60, 255, 96);
      }
    }
  }
  .title{
    margin-bottom: 30px;
    font-size: 24px;
    color: rgb(60, 255, 96);
    background-color: rgb(29, 29, 29);
    font-size: 40px;
    letter-spacing: 1px;
    line-height: 50px;
    padding: 0px 15px;
  }
  .tv-block{
    display: flex;
    position: relative;
    margin-bottom: 30px;
    width: 80%;
    justify-content: center;
    img{
      // height: 100%;
      width: 350px;
      max-width: 84vw;
    }
    .tv-text{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -80%);
      color: rgb(60, 255, 96);
      font-size: 10vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p{
        padding: 0;
        margin: 0;
      }
      .large{
        line-height: 10vh;
      }
      .little{
        font-size: 2vh;
      }
    }
  }
  .text{
    font-size: 16px;
  }
}
</style>

<script>
export default {
  name: '404'
}
</script>
