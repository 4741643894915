import { render } from "./Contact.vue?vue&type=template&id=77670cb6&lang=pug"
import script from "./Contact.vue?vue&type=script&lang=js"
export * from "./Contact.vue?vue&type=script&lang=js"

import "./Contact.vue?vue&type=style&index=0&id=77670cb6&lang=scss"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QRadio,QToggle,QBtn,QField});
