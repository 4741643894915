<template lang="pug">
#contact-page
  .contact-page
    Contact
</template>
<style lang="scss">
.contact-page{
  width: 100%;
  padding: 60px 0px 0px 0px;
}
</style>
<script>
import Contact from '../components/home/Contact.vue'
export default {
  components: {
    Contact
  },
  setup () {
    return {
    }
  }
}
</script>
