<template lang="pug">
#blog-page
  .blog-page
    .blog-center
      .title
        h2.ch 部落格
        h2.en Blog
      .list-space
        .list(v-for="(post, index) in post" :key="index" )
            router-link(:to="`/blog/${post.url}`")
              q-responsive(:ratio="16/6.5")
                img(:src="post.imgSrc")
            .bottom
              .title {{ post.title }}
              .date 發布日期&ensp; {{ post.date }}
              .summary {{ post.summary }}
              router-link(:to="`/blog/${post.url}`" class="view-btn") View
</template>
<style lang="scss">
.blog-page{
  padding: 70px 70px 30px 70px;
  width: 100%;
  min-height: calc(100vh - 180px);
  .blog-center{
    padding: 30px 30px 0px 30px;
    .title{
      display: flex;
      // border: solid 3px;
      margin-bottom: 30px;
      h2{
        font-size: 28px;
        line-height: 28px;
        margin: 0px;
        font-weight: 900 !important;
      }
      .ch{
        margin-right: 10px;
        letter-spacing: 1px;
      }
    }
    .list-space{
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px 0px;
      .list{
        width: 49%;
        border: solid 2px #000;
        border-radius: 5px;
        a{
          text-decoration: none;
          color: #000;
          display: flex;
          padding: 20px 20px 0px 20px;
          .q-responsive{
            overflow: hidden;
            width: 100%;
            border: solid 2px #000;
            border-radius: 5px;
            .q-responsive__content > img{
              max-height: none !important;
              max-width: none !important;
              width: 100% !important;
              height: auto !important;
              &:hover{
                width: 130%;
              }
            }
          }
        }
        .bottom{
          padding: 20px;
          .title{
            margin: 0px 0px 10px 0px;
            font-size: 20px;
          }
          .date{
            margin: 0px 0px;
            color: rgb(150, 150, 150);
            font-size: 12px;
          }
          .summary{
            margin: 10px 0px;
            font-weight: 700;
            letter-spacing: 1px;
          }
          .view-btn{
            display: inline-block;
            margin: 5px 0px;
            background-color: #000;
            color: #fff;
            padding: 3px 15px;
            transition-duration: .5s;
            &:hover{
              letter-spacing: 3px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .blog-page{
    padding: 70px 0px 10px 0px;
  }
}
@media (max-width: 900px) {
  .blog-page{
    padding: 70px 0px 0px 0px;
    .blog-center{
      .list-space{
        .list{
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  components: {
  },
  setup () {
    const store = useStore()
    return {
      post: computed(() => store.state.posts)
    }
  }
}
</script>
