import { render } from "./Service.vue?vue&type=template&id=7d149db4&lang=pug"
import script from "./Service.vue?vue&type=script&lang=js"
export * from "./Service.vue?vue&type=script&lang=js"

import "./Service.vue?vue&type=style&index=0&id=7d149db4&lang=scss"
script.render = render

export default script
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSplitter,QTabPanel,QTabs,QTab});
