<template lang="pug">
#main
  CoolBar(v-bind:parent-open="isOpen" @closeList="closeList")
  //- q-drawer(v-model="drawerLeft" :breakpoint="700" elevated :width="330")
  //-   q-scroll-area.fit
  //-     .title
  //-       h4
  //-         span.cool 酷
  //-         span 的 東 西
  //-       .close(@click="drawerLeft = !drawerLeft") X
  //-     .item
  //-       .name
  //-         span.icon
  //-           img(src="../../assets/game.svg")
  //-         span 小遊戲 Games
  //-     .item
  //-       .name
  //-         span.icon
  //-           img(src="../../assets/effect.svg")
  //-         span 特效 Effects
  q-page-container.main
    .main-center
      #p5-container
      .top-bar
        .left
          .left-inner
            img.four(src="../../assets/cool-four.svg")
            .cool-item
              .arrow
                img(src="../../assets/arrow.svg")
              .menu-btn(@click="open ()")
                img.three-line(src="../../assets/three-line.svg")
                img.menu(src="../../assets/menu.svg")
        .center
          img(src="../../assets/cool-text.svg")
          h1 網頁設計 ＆ 系統開發
        .right
          .outer
            .bg
            .calendar
              .month {{ getCalendar.month[0].name }}
              .date {{ getCalendar.date }}
      .center-bar
        .left
          img(src="../../assets/taiwan.svg")
          .time {{ hour}} : {{ minute }} : {{ second }}
        .center
          //- h1 網頁設計 ＆ 系統開發
          img(src="../../assets/cool-center.svg")
          //- .service-btn(@click="scroll()")
          //-   .bg
          //-   .btn 服務項目
        .right
      .bottom-bar
        .service-btn(@click="scroll()")
          .bg
          .btn 服務項目
        h2 Web Design / System Development
    .main-bottom
      Marquee
</template>
<style lang="scss">
#p5-container {
  width: calc(100vw - 60px);
  height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: solid 2px #000;
  // display: none;
}
// #main .q-drawer--left{
//   padding: 80px 20px 20px 80px;
//   box-shadow: 3px -3px 4px 1px  rgba(0, 0, 0, 0.1);
//   border: solid 2px #000;
//   background-color: #f4f4f4 !important;
//   background-size: 10px 10px !important;
//   .q-layout__shadow{
//     display: none;
//     &:after{
//       top: 10px !important;
//     }
//   }
//   .title{
//     margin-bottom: 20px;
//     display: flex;
//     align-items: stretch;
//     justify-content: space-between;
//     padding: 4px;
//     h4{
//       font-weight: 900;
//       .cool{
//         border: solid 3px #000;
//         padding: 0px 5px;
//         margin-right: 5px;
//         border-radius: 5px;
//       }
//     }
//     .close{
//       border: solid 3px #000;
//       height: 100%;
//       font-size: 28px;
//       padding: 0px 10px;
//       line-height: 35px;
//       border-radius: 5px;
//       color: #fff;
//       background-color: #000;
//     }
//     h4{
//       margin: 0;
//       font-size: 28px;
//     }
//   }
//   .item{
//     border: solid 2px;
//     padding: 5px 18px;
//     margin-bottom: 20px;
//     border: solid 2px #eee;
//     background-color: #000;
//     border-radius: 10px;
//     &:hover{
//       .name{
//         letter-spacing: 2px;
//       }
//     }
//     .name{
//       margin: 0;
//       font-size: 16px;
//       line-height: 16px;
//       color: #fff;
//       letter-spacing: 1px;
//       transition-duration: .5s;
//       display: flex;
//       align-items: center;
//       .icon{
//         margin-right: 10px;
//         width: 24px;
//         transform: translateY(1px);
//       }
//     }
//   }
// }
#main{
  overflow: hidden;
}
.main{
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  padding: 60px 60px calc(4vh + 60px) 60px !important;
  border-bottom: solid 2px #000;
  .main-center{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    position: relative;
    .top-bar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 20%;
      min-height: 100px;
      max-height: 120px;
      z-index: 99;
      .left, .center, .right{
        flex: 1;
      }
      .left{
        display: flex;
        justify-content: flex-start;
        height: 100%;
        .left-inner{
          display: flex;
          flex-direction: column;
          height: 100%;
          .four{
            height: 50%;
            width: 100%;
            z-index: 99;
          }
          .cool-item{
            display: flex;
            align-items: center;
            position: relative;
            height: 50%;
            width: 100%;
            .arrow{
              display: flex;
              flex: 1;
              height: 100%;
              align-items: center;
              img{
                height: 80%;
              }
            }
            .menu-btn{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex: 3;
              background-color: #000;
              border-radius: 2px;
              height: 70%;
              padding: 2%;
              .three-line{
                height: 100%;
                margin-right: 5%;
              }
              .menu{
                height: 100%;
              }
            }
          }
        }
      }
      .center{
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        z-index: 99;
        img{
          height: 40%;
          z-index: 99;
        }
        h1{
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 2px;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          margin-bottom: 10px;
          font-weight: 900 !important;
        }
      }
      .right{
        display: flex;
        justify-content: flex-end;
        height: 100%;
        .outer{
          height: 95%;
          position: relative;
          // transform: translateX(calc(40px + 20px));
          .bg{
            position: absolute;
            border-radius: 2px;
            width: 100%;
            height: 100%;
            background-color: #000;
            transform: translate(5px, 5px);
          }
          .calendar{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 15px;
            height: 100%;
            border: solid 2px #000;
            border-radius: 2px;
            position: relative;
            background-color: #fff;
            .month{
              font-size: 28px;
              line-height: 28px;
            }
            .date{
              font-size: 50px;
              line-height: 50px;
            }
          }
        }
      }
    }
    .center-bar{
      height: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      z-index: 99;
      // border: solid 3px #000;
      .left{
        display: flex;
        align-items: center;
        flex:1;
        z-index: 99;
        img{
          width: 4vh;
          margin-right: 15px;
        }
        .time{
          font-size: 18px;
          white-space: nowrap;
        }
      }
      .right{
        flex: 1;
      }
      .center{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // h1{
        //   font-size: 24px;
        //   line-height: 24px;
        //   letter-spacing: 2px;
        //   transform: skewX(-10deg);
        //   margin: 0;
        //   padding: 0;
        //   margin-bottom: 3vh;
        // }
        img{
          height: 70%;
          z-index: 9;
          max-height: 400px;
          margin-left: 15px;
        }
        // .service-btn{
        //   position: relative;
        //   z-index: 99;
        //   margin-top: 30px;
        //   &:hover{
        //     .btn{
        //       transform: translateY(-3px);
        //     }
        //   }
        //   .btn{
        //     font-size: 18px;
        //     letter-spacing: 5px;
        //     border: solid 3px #fff;
        //     padding: 7px 70px;
        //     border-radius: 30px;
        //     margin: 0;
        //     background-color: #000;
        //     transition-duration: .5s;
        //     transform: translateY(-8px);
        //     color: #fff;
        //   }
        //   .bg{
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background-color: #000;
        //     bottom: 0px;
        //     z-index: -9;
        //     border-radius: 20px;
        //   }
        // }
      }
    }
    .bottom-bar{
      height: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 99;
      .service-btn{
        position: relative;
        z-index: 99;
        margin-bottom: 25px;
        &:hover{
          .btn{
            transform: translateY(-3px);
          }
        }
        .btn{
          font-size: 18px;
          letter-spacing: 5px;
          border: solid 3px #fff;
          padding: 7px 70px;
          border-radius: 30px;
          margin: 0;
          background-color: #000;
          transition-duration: .5s;
          transform: translateY(-8px);
          color: #fff;
        }
        .bg{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #000;
          bottom: 0px;
          z-index: -9;
          border-radius: 20px;
        }
      }
      h2{
        margin: 0px;
        font-size: 24px;
        line-height: 24px;
        transform: skewX(-10deg);
        font-weight: 900 !important;
      }
    }
  }
  .main-bottom{
    position: relative;
    height: 60px;
  }
}
@media (max-width: 1280px) {
  .main{
    .main-center{
      .center-bar{
        .left{
          img, .time{
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .main{
    padding: 60px 0px calc(4vh + 60px) 0px !important;
    #p5-container{
      display: none;
    }
  }
}
@media (max-width: 900px) {
  #main{
    display: none;
  }
}
</style>
<script>
import CoolBar from '../CoolBar.vue'
import Marquee from '../Marquee.vue'
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
// import createP5 from '../../p5/p5.js'
export default {
  components: {
    Marquee,
    CoolBar
  },
  setup () {
    gsap.registerPlugin(ScrollToPlugin)
    // const drawerLeft = ref(false)
    const hour = ref(new Date().getHours())
    const minute = ref(new Date().getMinutes())
    const second = ref(new Date().getSeconds())
    const isOpen = ref(false)
    const calendar = [
      {
        index: 0,
        name: 'Jan.'
      },
      {
        index: 1,
        name: 'Feb.'
      },
      {
        index: 2,
        name: 'Mar.'
      },
      {
        index: 3,
        name: 'Apr.'
      },
      {
        index: 4,
        name: 'May.'
      },
      {
        index: 5,
        name: 'Jun.'
      },
      {
        index: 6,
        name: 'Jul.'
      },
      {
        index: 7,
        name: 'Aug.'
      },
      {
        index: 8,
        name: 'Sep.'
      },
      {
        index: 9,
        name: 'Oct.'
      },
      {
        index: 10,
        name: 'Nov.'
      },
      {
        index: 11,
        name: 'Dec.'
      }
    ]
    const getCalendar = computed(() => {
      const e = new Date()
      const m = e.getMonth()
      const date = e.getDate()
      const month = calendar.filter(e => e.index === m)
      return {
        month,
        date
      }
    })
    const now = setInterval(() => {
      const e = new Date()
      if (e.getHours().toString().length < 2) {
        hour.value = '0' + e.getHours()
      } else {
        hour.value = e.getHours()
      }
      if (e.getMinutes().toString().length < 2) {
        minute.value = '0' + e.getMinutes()
      } else {
        minute.value = e.getMinutes()
      }
      if (e.getSeconds().toString().length < 2) {
        second.value = '0' + e.getSeconds()
      } else {
        second.value = e.getSeconds()
      }
    }, 1000)
    function closeList (data) {
      isOpen.value = data
    }
    onBeforeUnmount(() => {
      if (now) {
        clearInterval(now)
      }
    })
    onMounted(() => {
      // createP5('p5-container')
      // NOTE: Use p5 as an instance mode
    })

    return {
      // drawerLeft,
      hour,
      minute,
      second,
      getCalendar,
      isOpen,
      closeList,
      scroll () {
        gsap.to(window, { duration: 1, scrollTo: '#service' })
      },
      open () {
        isOpen.value = !isOpen.value
      }
    }
  }
}
</script>
