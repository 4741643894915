<template lang="pug">
.service
  #service
  .service-center
    .title
      h4.en SERVICE
      h4.ch " 服務項目 "
    .tv-block
      .item
        .tv-box
          .dot.dot1
          .dot.dot2
          .dot.dot3
          .dot.dot4
          .dot.dot5
          .dot.dot6
          .dot.dot7
          .dot.dot8
          .tv
            //- img(src="../../assets/website2.png")
            video.web(autoplay muted loop playsinline)
              source(src="../../assets/website.mp4" type="video/mp4")
        //- h4.name 客製化網站設計
        .tab-block
          WebTabs
      .item
        .tv-box
          .dot.dot1
          .dot.dot2
          .dot.dot3
          .dot.dot4
          .dot.dot5
          .dot.dot6
          .dot.dot7
          .dot.dot8
          .tv
            //- img(src="../../assets/tv.svg")
            video.system(autoplay muted loop playsinline)
              source(src="../../assets/system.mp4" type="video/mp4")
        .tab-block
          SystemTabs
</template>
<style lang="scss">
.focus{
  border-top: solid 2px #000;
  border-bottom: solid 2px #000;
  color: #fff;
}
.service{
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #fff;
  padding: 0px 60px;
  border-bottom: solid 2px #000;
  position: relative;
  overflow: hidden;
  #service{
    height: 60px;
    position: absolute;
    top: -60px;
  }
  .service-center{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .title{
      display: flex;
      margin-bottom: 20px;
      .en, .ch{
        margin: 0;
        font-weight: 900 !important;
      }
      .en{
        font-size: 30px;
        line-height: 28px;
        margin-right: 10px;
        padding: 0;
      }
      .ch{
        font-size: 16px;
        line-height: 20px;
        padding: 6px 15px;
        letter-spacing: 2px;
        background-color: #000;
        color: #fff;
        border-radius: 2px;
      }
    }
    .tv-block{
      display: flex;
      justify-content: space-between;
      height: calc(100% - 60px);
      .item{
        width: 49%;
        height: 100%;
        // border: solid 3px;
        margin: 3px;
        .tv-box{
          height: 65%;
          position: relative;
          border: solid 2px #000;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: #f4f4f4;
          z-index: 99;
          background-image: radial-gradient(#5a5a5a 0.5px, #f4f4f4 0.5px);
          background-size: 10px 10px;
          .dot{
            width: 10px;
            height: 10px;
            position: absolute;
            background-color: #fff;
            border: solid 2px #000;
          }
          .dot1{
            left: -6px;
            top: -6px;
          }
          .dot2{
            left: calc(50% - 5px);
            top: -6px;
          }
          .dot3{
            right: -6px;
            top: -6px;
          }
          .dot4{
            left: -6px;
            top: calc(50% - 5px);
          }
          .dot5{
            right: -6px;
            top: calc(50% - 5px);
          }
          .dot6{
            left: -6px;
            bottom: -6px;
          }
          .dot7{
            left: calc(50% - 5px);
            bottom: -6px;
          }
          .dot8{
            right: -6px;
            bottom: -6px;
          }
          .tv{
            width: 18vw;
            height: 17vw;
            margin: 6vh 0px;
            display:  flex;
            justify-content: center;
            border: solid 3px #000;
            border-radius: 8px;
            overflow: hidden;
            video.web{
              // mix-blend-mode: screen;
              height: 102%;
              transform: translate(-.5%, -1%);
            }
            video.system{
              // mix-blend-mode: screen;
              height: 108%;
              transform: translate(.17%, -3.7%);
            }
            img{
              height: 100%;
            }
          }
        }
        h4{
          font-size: 20px;
          letter-spacing: 1px;
        }
        .tab-block{
          border-left: solid 2px;
          border-right: solid 2px;
          border-bottom: solid 2px;
          height: 35%;
          width: 100%;
          .q-splitter{
            height: 100%;
            .q-splitter__before{
              background-color: #f4f4f4;
            }
            // .q-tab-panel, .q-splitter__after{
            //   background-color: #f4f4f4 !important;
            // }
            .q-tabs--vertical{
              .q-tabs__content{
                padding: 10px 0px;
              }
              .q-tab{
                .q-tab__label{
                  font-size: 18px !important;
                  letter-spacing: 1px;
                }
              }
            }
            .text-h5{
              font-size: 18px !important;
              letter-spacing: 1px;
              // border-bottom: dashed 2px #333;
              // padding-bottom: 10px;
              border-radius: 2px;
              padding: 3px 15px;
              background-color: #000;
              color: #fff;
            }
            a{
              text-decoration: none;
              color: #000;
              letter-spacing: 1px;
              // background-color: #000;
              // padding: 5px 15px;
              border-radius: 2px;
              margin-top: 15px;
              display: inline-flex;
              align-items: center;
              img{
                height: 30px;
                margin-right: 5px;
              }
              p{
                margin: 0;
                padding: 3px 0px;
                // border-top: dashed 2px #000;
                border-bottom: dashed 2px #000;
              }
            }
            .q-splitter__separator{
              background-color: #000;
              width: 2px;
            }
            .q-tab__indicator{
              // border-top: solid 1px rgba(0, 0, 0, 0.12);
              border-top: solid 2px rgba(0, 0, 0, 1);
              border-bottom: solid 2px rgba(0, 0, 0, 1);
              background-color: #fff;
              width: 100%;
              z-index: -1;
              &::after{
                content: "➔";
                color: #000;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .service{
    height: auto;
    padding: 0px 30px;
    .service-center{
      padding: 40px 0px;
      .tv-block{
        flex-direction: column;
        .item{
          width: 100%;
          height: auto;
          margin: 10px 0px;
          .tv-box{
            height: 50vw;
            .tv{
              height: 70%;
              width: 37vw;
              border-radius: 5px;
              border: solid 2px #000;
            }
          }
          .tab-block{
            .q-splitter{
              flex-direction: column;
              .q-splitter__panel{
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 380px) {
  .service{
    .service-center{
      .title{
        .en{
          font-size: 26px;
        }
        .ch{
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<script>
// import { ref } from 'vue'
import WebTabs from '../../components/WebTabs.vue'
import SystemTabs from '../../components/SystemTabs.vue'
export default {
  components: {
    WebTabs,
    SystemTabs
  },
  setup () {
    return {
      // tab: ref('客製化網頁設計建構'),
      // splitterModel: ref(250)
    }
  }
}
</script>
