<template lang="pug">
#home
  Main
  MobileMain
  Service
  Contact
</template>
<style lang="scss">
</style>
<script>
import Main from '../components/home/Main.vue'
import MobileMain from '../components/home/MobileMain.vue'
import Service from '../components/home/Service.vue'
import Contact from '../components/home/Contact.vue'
export default {
  components: {
    Main,
    MobileMain,
    Service,
    Contact
  },
  setup () {
    return {
    }
  }
}
</script>
