<template lang="pug">
.cool-bar(:class="{ openMenu: open }")
  .inner
    .title
      .cool-title
        span.cool 酷
        span 的 東 西
      .close(@click="closeCoolList") X
    .warn
      span.box
      span 準備中 Coming Soon!!
    .item
      .name
        span.icon
          img(src="../assets/game.svg")
        span 小遊戲 Games
    .item
      .name
        span.icon
          img(src="../assets/effect.svg")
        span 特效 Effects
</template>
<style lang="scss">
  .cool-bar{
    position: fixed;
    width: 400px;
    height: 100vh;
    top: 0;
    // right: calc(100vw - 70px);
    left: calc(-340px);
    transition-duration: 1s;
    z-index: 999;
    border: solid 2px rgb(0, 0, 0);
    .inner{
      background-color: #f4f4f4;
      padding: 90px 30px 20px 90px;
      height: 100%;
      .title{
        display: flex;
        font-size: 24px;
        margin-bottom: 30px;
        justify-content: space-between;
        .cool-title{
          font-weight: 900;
          .cool{
            border: solid 3px #000;
            padding: 0px 7px;
            margin-right: 8px;
            border-radius: 5px;
          }
        }
        .close{
          display: flex;
          justify-content: flex-end;
          border: solid 3px #000;
          line-height: 30px;
          padding: 0px 10px;
          border-radius: 3px;
          transition-duration: .5s;
          &:hover{
            background-color: #000;
            color: #fff;
          }
        }
      }
      .warn{
        margin-bottom: 30px;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        .box{
          width: 20px;
          height: 20px;
          background-color: #000;
          margin-right: 10px;
          border-radius: 3px;
        }
      }
      .item{
        background-color: #000;
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 5px 10px;
        letter-spacing: 1px;
        &:hover{
          letter-spacing: 3px;
        }
        .name{
          display: flex;
          align-items: center;
          .icon{
            display: flex;
            margin-right: 15px;
            img{
              width: 30px;
            }
          }
          span{
            color: #fff;
            transition-duration: .5s;
          }
        }
      }
    }
  }
  .openMenu{
    left: 0px;
  }
  @media (max-width: 1200px) {
    .cool-bar{
      left: calc(-300px);
      width: 300px;
      .inner{
        padding: 90px 30px 20px 30px;
      }
    }
    .openMenu{
      left: 0px;
    }
  }
  @media (max-width: 420px) {
    .cool-bar{
      left: -100vw;
      width: 100vw;
      .inner{
        padding: 90px 30px 20px 30px;
      }
    }
    .openMenu{
      left: 0px;
    }
  }
</style>

<script>
import { toRef } from 'vue'
export default {
  name: 'CoolBar',
  props: ['parentOpen'],
  emits: ['closeList'],
  setup (props, context) {
    const open = toRef(props, 'parentOpen')
    const closeCoolList = () => {
      context.emit('closeList', false)
    }
    return {
      open,
      // openList () {
      //   open.value = true
      // },
      // closeList () {
      //   open.value = false
      // },
      closeCoolList
    }
  }
}
</script>
