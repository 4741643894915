<template lang="pug">
.mobile-header
  q-header(elevated)
    q-toolbar
      router-link(to="/" class="left" @click="drawerRight = false")
        img(src="../assets/cool-icon.svg")
      .center
        router-link(to="/" @click="drawerRight = false")
          img(src="../assets/cool-text.svg")
      .right
        q-btn(flat @click="drawerRight = !drawerRight" dense icon="r_menu")
  q-drawer(v-model="drawerRight" :width="$q.screen.width" :breakpoint="0" elevated side="right")
    .menu
      .signboard
        img(src="../assets/cool-menu-title.svg")
        p.word "網頁の設計 和 系統の開發"
      ul
        li(@click="drawerRight = !drawerRight")
          router-link(to="/web")
            .btn-ch 網頁設計
            .btn-en Web
        li(@click="drawerRight = !drawerRight")
          router-link(to="/system")
            .btn-ch 系統開發
            .btn-en System
        li(@click="drawerRight = !drawerRight")
          router-link(to="/contact")
            .btn-ch 聯絡我們
            .btn-en Contact
        li(@click="drawerRight = !drawerRight")
          router-link(to="/project-list")
            .btn-ch 酷の產品
            .btn-en product
        li(@click="drawerRight = !drawerRight")
          router-link(to="/blog")
            .btn-ch 部落格
            .btn-en Blog
      .copyright Cool Stuff Studio©2021 Copyright.
</template>
<style lang="scss">
.mobile-header{
  position: fixed;
  width: 100vw;
  z-index: 9997;
  display: none;
  .q-header{
    padding: 5px;
    display: flex;
    border-bottom: solid 2px #000;
    .q-toolbar{
      display: flex;
      justify-content: space-between;
      .left{
        display: flex;
        img{
          height: 30px;
        }
      }
      .center{
        display: flex;
        a{
          display: flex;
          img{
            height: 30px;
          }
        }
      }
      .right{
        .q-btn{
          background-color: #000;
        }
      }
    }
    .space{
      width: 100px;
    }
  }
  .q-drawer{
    background-color: #f4f4f4;
    height: calc(100vh - 60px) !important;
    // height: 100vh !important;
    .menu{
      // border: solid 3px #fff;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding: 30px;
      .signboard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        img{
          width: 50vw;
        }
        .word{
          letter-spacing: 1px;
          margin-top: 5px;
        }
      }
      ul{
        list-style: none;
        padding: 0;
        // transform: translateY(-70px);
        width: 100%;
        li{
          font-weight: 900;
          letter-spacing: 1px;
          text-align: center;
          margin-bottom: 25px;
          width: 100%;
          // height: 50px;
          border-radius: 3px;
          background-color: #000;
          transform: translate(3px, 3px);
          .btn-ch{
            font-size: 14px;
          }
          .btn-en{
            font-size: 12px;
          }
          a{
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #000;
            text-decoration: none;
            background-color: #fff;
            border: solid 3px #000;
            transform: translate(-7px, -7px);
            border-radius: 4px;
            padding: 5px;
            transition-duration: .5s;
            &:hover{
              transform: translate(0px, 0px);
            }
          }
        }
      }
      .copyright{
        margin: 30px 0px;
        white-space: nowrap;
      }
    }
  }
}
@media (max-width: 1140px) {
  .mobile-header{
    display: block;
  }
}
</style>
<script>
import { ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)
export default {
  components: {
  },
  setup () {
    const drawerRight = ref(false)
    return {
      drawerRight
    }
  }
}
</script>
