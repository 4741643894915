<template lang="pug">
#post
  .post
    .post-center
      .title
        .dot
        h1 {{ post.title }}
      .date 發佈日期 - {{ post.date }}
      .content(v-html="post.content")
</template>
<style lang="scss">
.post{
  // padding: 60px 90px 30px 90px;
  padding: 70px 70px 10px 70px;
  letter-spacing: 1px;
  width: 100%;
  // max-width: 1200px;
  .post-center{
    padding: 30px 30px 10px 30px;
    h1{
      font-size: 24px;
      line-height: 34px;
      margin: 0;
      font-weight: 900;
    }
    h2{
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      font-weight: 700;
    }
    h4{
      font-size: 15px;
      line-height: 26px;
      font-weight: 700;
      margin-top: 20px;
    }
    p{
      font-size: 15px;
      font-weight: 500;
    }
    ol{
      font-size: 15px;
      // list-style: none;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 20px;
      font-weight: 500;
      background-color: #f4f4f4;
      li{
        margin: 10px 0px;
      }
    }
    hr{
      border: solid 2px #eee;
      margin: 30px 0px;
    }
    img{
      width: 100%;
      border: solid 2px #333;
      margin-top: 10px;
    }
    .title{
      display: flex;
      align-items: stretch;
      margin-bottom: 20px;
      .dot{
        width: 25px;
        background-color: #000;
        margin-right: 15px;
        border: solid 3px #000;
      }
    }
    .date{
      color: #888;
      // border: solid 3px #000;
    }
    .content{
      margin: 20px 0px;
    }
  }
}
@media (max-width: 1200px) {
  .post{
    padding: 70px 0px 10px 0px;
  }
}
</style>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  components: {
  },
  setup () {
    // const post = reactive({})
    const store = useStore()
    const route = useRoute()
    const id = route.params.blogId
    return {
      post: computed(() => store.state.posts.filter(post => post.url === id)[0])
    }
  }
}
</script>
