import P5 from 'p5'
function createP5 (parentId) {
  const p = sketch => {
    const particles = []
    const squiggliness = 1 / 100
    sketch.setup = () => {
      console.log(sketch)
      sketch.createCanvas(sketch.windowWidth, sketch.windowHeight)
      sketch.colorMode(sketch.HSB, 100)
      sketch.stroke(90, 100)
      sketch.noFill()
      sketch.background(255)
    }
    sketch.draw = () => {
      sketch.beginShape()
      for (const p of particles) {
        p.draw()
        p.move()
      }
      sketch.endShape()
      if (sketch.frameCount < 632.01) {
        sketch.updateParticles(1, sketch.frameCount / 100)
      } else if (sketch.frameCount > 1000) {
        sketch.noLoop()
      }
    }
    sketch.updateParticles = (r, theta0) => {
      const n = 1
      for (let i = 0; i < n; i++) {
        const theta = theta0 + sketch.map(i, 0, n, -sketch.PI, sketch.PI)
        const x_ = sketch.width / 2 + r * sketch.cos(theta)
        const y_ = sketch.height / 2 + r * sketch.sin(theta)
        const s_ = 1
        const c_ = sketch.color(95)
        particles.push(new Particle(x_, y_, s_, c_))
      }
    }
    function Particle (x_, y_, s_, c_) {
      this.x = x_
      this.y = y_
      this.size = s_
      this.c = c_

      this.alpha = 100
      this.dist = 10

      this.move = function () {
        let theta = sketch.atan2(this.y - sketch.height / 2, this.x - sketch.width / 2)
        theta += sketch.noise(this.x * squiggliness, this.y * squiggliness) * 2
        const v = P5.Vector.fromAngle(theta, this.dist)
        this.x += v.x
        this.y += v.y
      }

      this.draw = function () {
        this.c.setAlpha(this.alpha)
        sketch.curveVertex(this.x, this.y)
        this.c.setAlpha(100)
      }
    }
  }
  return new P5(p, parentId)
}
export default createP5
