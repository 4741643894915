import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import WebPage from '../views/WebPage.vue'
import SystemPage from '../views/SystemPage.vue'
import ContactPage from '../views/ContactPage.vue'
import Blog from '../views/BlogPage.vue'
import Post from '../views/Article.vue'
import ProjectList from '../views/ProjectList.vue'
import Project from '../views/Project.vue'
import NotFound from '../views/404Page.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/web',
    name: 'Web',
    component: WebPage
  },
  {
    path: '/system',
    name: 'System',
    component: SystemPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/blog/:blogId',
    name: 'Post',
    component: Post
  },
  {
    path: '/project-list',
    name: 'project-list',
    component: ProjectList
  },
  {
    path: '/project/:projectId',
    name: 'project',
    component: Project
  },
  {
    path: '/blog/:blogId',
    name: 'Post',
    component: Post
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, saveTop) {
    if (saveTop) {
      return saveTop
    } else {
      return { left: 0, top: 0 }
    }
  }
  // scrollBehavior (to, from, savedPosition) {
  //   const w = window.innerWidth
  //   if (to.hash && (w < 890)) {
  //     return {
  //       el: to.hash,
  //       behavior: 'smooth',
  //       top: 70
  //     }
  //   } else {
  //     return {
  //       el: to.hash,
  //       behavior: 'smooth'
  //     }
  //   }
  // }
})

export default router
