<template lang="pug">
div.mobile-marquee-block
  div.marquee
    p 抱歉了錢錢，但我真的需要這個酷東西。Create a new Possibility and Keep it Cool.
</template>
<style lang="scss" scoped>
  .mobile-marquee-block{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #000;
    z-index: 98;
    .marquee{
      position: relative;
      padding: 10px 20px;
      height: 60px;
      overflow: hidden;
      display: flex;
      align-items: center;
      p{
        font-size: 18px;
        letter-spacing: 2px;
        color: #fff;
        margin: 0;
        position: absolute;
        right: -380px;
        white-space: nowrap;
      }
    }
  }
  @media (max-width: 380px) {
    .mobile-marquee-block{
      .marquee{
        height: 40px;
        p{
          font-size: 16px;
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'MobileMarquee',
  data () {
    return {
      mobileMarqueeEffect: '',
      mobileMarqueeText: ''
    }
  },
  mounted () {
    const that = this
    this.mobileMarqueeText = document.querySelector('.mobile-marquee-block .marquee p')
    const mobileMarqueeTextWidth = document.querySelector('.mobile-marquee-block .marquee p').offsetWidth
    function getMobileMarqueeWidth () {
      return document.querySelector('.mobile-marquee-block .marquee').offsetWidth
    }
    let mobileMarqueeWidth = getMobileMarqueeWidth()

    this.mobileMarqueeEffect = setInterval(mobileMarquee, 20)
    function mobileMarquee () {
      mobileMarqueeWidth -= 1
      that.mobileMarqueeText.style.left = mobileMarqueeWidth + 'px'
      if (mobileMarqueeWidth < mobileMarqueeTextWidth * -1) {
        mobileMarqueeWidth = getMobileMarqueeWidth()
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.mobileMarqueeEffect)
  }
}
</script>
