<template lang="pug">
.contact
  .contact-center
    .title
      h4.en CONTACT
      h4.ch " 聯絡我們 "
    .intro 對於網站設計或系統開發有相關問題可以透過以下表單填寫送出，我們將會回覆您的訊息或是直接與您聯繫。
    .form-block
      p.form-title 酷東西製作需求單
      q-form(@submit="onSubmit")
        .single
          q-input(v-model="company" label-color="black" label="公司名稱 *" hint="Your Company" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
        .together.window
          q-input(v-model="name" label-color="black" label="聯絡人*" hint="Contact Person" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
          .radio-block
            .radio-btn
              q-radio(v-model="gender" val="man" label="先生")
              q-radio(v-model="gender" val="women" label="小姐")
        .together
          q-input(v-model="mail" label-color="black" label="E-mail *" hint="E-mail" lazy-rules :rules='mailRule')
          .space
          q-input(v-model="phone" label-color="black" label="聯絡電話 *" hint="Number" lazy-rules :rules="phoneRule")
        .together
          .radio-block
            .radio-title 案件類型 *
            .radio-btn
              q-radio(v-model="caseType" val="web" label="網頁設計")
              q-radio(v-model="caseType" val="system" label="系統開發")
              q-radio(v-model="caseType" val="other" label="其他")
          .space
          q-input(v-model="intro" label-color="black" label="公司產業別 *" hint="Intro" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
        .single
          q-input(v-model="web" label-color="black" label="公司網站" hint="如原有公司網站，請填寫此欄位" :error="!webRule")
            template(v-slot:error) 請填入正確網址
        .together
          q-input(v-model="money" label-color="black" label="預算範圍 *" hint="請填入預算" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
          .space
          q-input(v-model="url" label-color="black" label="參考範例" hint="請填入網址" :error="!urlRule")
            template(v-slot:error) 請填入正確網址
        .single
          .textarea
            q-input(autogrow v-model="msg" label-color="black" label="需求簡述 *" hint="Message" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
        .together
          q-toggle(v-model="accept" label="I accept the license and terms" color="orange")
        .together
          div.btn-block
            q-btn(label="提交 Submit" type="submit" color="black")
            q-btn.q-ml-sm(label="Reset" type="reset" color="orange" flat="")
</template>
<style lang="scss">
.contact{
  width: 100%;
  background-color: #fff;
  padding: 0px 60px;
  .contact-center{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .title{
      display: flex;
      margin-bottom: 20px;
      .en, .ch{
        margin: 0;
        font-weight: 900 !important;
      }
      .en{
        font-size: 30px;
        line-height: 28px;
        margin-right: 10px;
        padding: 0;
      }
      .ch{
        font-size: 16px;
        line-height: 20px;
        padding: 6px 15px;
        letter-spacing: 2px;
        background-color: #000;
        color: #fff;
        border-radius: 2px;
      }
    }
    .intro{
      letter-spacing: 1px;
      font-size: 15px;
      color: #333333;
    }
    .form-block{
      margin-top: 20px;
      border: solid 2px;
      padding: 30px;
      border-radius: 3px;
      background-color: #f4f4f4;
      .form-title{
        display: inline-block;
        background-color: #000;
        padding: 5px 15px;
        letter-spacing: 1px;
        color: #fff;
      }
      .single{
        margin-bottom: 20px;
        .q-field__label{
          font-weight: 900 !important;
        }
      }
      .together{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .radio-block{
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 49%;
          .radio-title{
            font-size: 16px;
          }
          .radio-btn{
            margin: 0;
          }
        }
        .q-field{
          width: 49%;
          .q-field__label{
            font-weight: 900 !important;
          }
        }
      }
      .q-btn{
        font-weight: 900 !important;
      }
    }
  }
}
@media (max-width: 1200px){
  .contact{
    padding: 0px 30px;
    .contact-center{
      padding: 40px 0px;
    }
  }
}
@media (max-width: 780px) {
  .together{
    flex-direction: column;
    .q-field{
      width: 100% !important;
    }
  }
  .together.window{
    flex-direction: row;
    .radio-block{
      width: 180px !important;
      white-space: nowrap;
      // margin-left: 20px;
    }
  }
}
@media (max-width: 380px) {
  .contact{
    .contact-center{
      .title{
        .en{
          font-size: 26px;
        }
        .ch{
          font-size: 14px;
        }
      }
      .form-block{
        .together{
          flex-direction: column;
          .radio-block{
            margin-top: 10px;
            .radio-title{
              margin-bottom: 5px;
            }
          }
          .btn-block{
            display: flex;
            .q-btn{
              padding: 4px 13px;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import { useQuasar } from 'quasar'
import { ref, computed } from 'vue'
import validator from 'validator'
import { postForm } from '@/api/api'
export default {
  components: {
  },
  setup () {
    const $q = useQuasar()
    const company = ref(null)
    const name = ref(null)
    const gender = ref(null)
    const mail = ref(null)
    const phone = ref(null)
    const intro = ref(null)
    const msg = ref(null)
    const caseType = ref(null)
    const money = ref(null)
    const web = ref('')
    const url = ref('')
    const accept = ref(false)
    const mailRule = ref(
      [
        (val) => (val && val.length > 0) || 'Please type something',
        (val) => validator.isEmail(val) || '請輸入正確的電子郵件格式',
        (val) => val?.includes('gmail') || '信箱只能使用 Gmail',
        (val) => {
          const [, tailStr] = val.split('@')
          return !tailStr.includes('.tw') || '不可以使用 .tw 結尾的信箱'
        }
      ]
    )
    const phoneRule = ref(
      [
        (val) => (val && val.length > 0) || 'Please type something',
        (val) => {
          // const re = new RegExp('^09\\d{{8}}$')
          const normal = new RegExp('^\\(?(\\d{2})\\)?[\\s\\-]?(\\d{4})\\-?(\\d{4})$')
          const mobile = new RegExp('^09\\d{8}$')
          // const re = new RegExp('^(\\d{2,3}-?|\\(\\d{2,3}\\))\\d{3,4}-?\\d{4}|09\\d{2}(\\d{6}|-\\d{3}-\\d{3})')
          return (normal.test(val) || mobile.test(val)) || '請輸入正確的電話格式'
        }
      ]
    )

    return {
      company,
      name,
      gender,
      mail,
      phone,
      intro,
      msg,
      caseType,
      money,
      web,
      url,
      accept,
      mailRule,
      phoneRule,
      webRule: computed(() => {
        if (web.value.length === 0) {
          return true
        } else {
          return validator.isURL(web.value)
        }
      }),
      urlRule: computed(() => {
        if (url.value.length === 0) {
          return true
        } else {
          return validator.isURL(url.value)
        }
      }),

      onSubmit () {
        const companyOriginalWebsite = web.value === '' ? undefined : web.value
        const referenceExample = url.value === '' ? undefined : url.value
        const collectData = {
          companyName: company.value,
          contactPerson: name.value,
          gender: gender.value,
          email: mail.value,
          phone: phone.value,
          caseType: caseType.value,
          companyIndustry: intro.value,
          companyOriginalWebsite,
          budgetRange: money.value,
          referenceExample,
          conceptDescription: msg.value
        }

        if (accept.value !== true) {
          $q.notify({
            color: 'negative',
            textColor: 'white',
            message: '請先點擊接受許可 Accept the license and terms first',
            position: 'center'
          })
        } else {
          postForm(collectData).then((result) => {
            console.log('[ postForm ] result :', result)
            $q.notify({
              color: 'secondary',
              textColor: 'white',
              message: 'Cool Submit 提交成功',
              position: 'center'
            })
          }).catch((err) => {
            $q.notify({
              color: 'negative',
              textColor: 'white',
              message: 'Failed to submit 送出失敗',
              position: 'center'
            })
            console.log('[ postForm ] err :', err.response)
          })
        }
      },
      onReset () {
        company.value = null
        name.value = null
        intro.value = null
        msg.value = null
        accept.value = false
      }
    }
  }
}
</script>
