import { render } from "./ProjectList.vue?vue&type=template&id=4e15e554&scoped=true&lang=pug"
import script from "./ProjectList.vue?vue&type=script&lang=js"
export * from "./ProjectList.vue?vue&type=script&lang=js"

import "./ProjectList.vue?vue&type=style&index=0&id=4e15e554&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4e15e554"

export default script
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg});
