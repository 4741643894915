<template lang="pug">
#Project
  .project
    .project-center
      div.top
        .title
          h1 『 {{ product.name }}  』
        a.btn(:href="product.productLink" target="_blank")
          <svg width="20" height="20" viewBox="0 0 115 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.3598 114.72C88.6828 114.72 114.72 88.6828 114.72 57.3598C114.72 25.9806 88.6266 0 57.3036 0C25.9242 0 0 25.9806 0 57.3598C0 88.6828 25.9806 114.72 57.3598 114.72ZM82.3283 31.2105C89.3013 38.1274 88.6828 46.6751 80.6411 54.6605L70.9687 64.3892C71.756 61.6899 71.6998 58.5407 70.7998 56.4039L76.4798 50.7241C81.9907 45.3255 82.6095 39.6457 78.223 35.3156C73.8928 31.0418 68.2133 31.7165 62.8145 37.1151L54.773 45.0443C49.2055 50.6677 48.4747 56.4039 52.8609 60.6776C54.1544 62.0272 56.0665 62.8709 58.5407 63.3208C57.6971 65.1203 55.954 67.201 54.3231 68.3256C52.6361 68.0444 50.499 66.6947 48.6995 64.8391C41.7264 57.9222 42.4575 49.2619 50.6115 41.0515L58.8219 32.8975C66.8637 24.8558 75.4114 24.2934 82.3283 31.2105ZM30.3669 83.1717C23.3936 76.2548 24.0121 67.707 32.1103 59.7217L41.7826 49.993C40.9392 52.6923 40.9954 55.8414 41.8951 57.9783L36.2153 63.6581C30.7042 69.0005 30.0857 74.7365 34.4722 79.0666C38.8021 83.3404 44.5383 82.6657 49.8805 77.2671L57.9222 69.3379C63.4894 63.7142 64.2205 57.9783 59.8342 53.7046C58.5407 52.3547 56.6287 51.5113 54.1544 51.0614C54.9978 49.2619 56.7412 47.1812 58.3721 46.0566C60.0591 46.3375 62.1959 47.6872 64.0518 49.5431C70.9687 56.46 70.2376 65.0639 62.0836 73.2743L53.8732 81.4847C45.8315 89.5264 37.2838 90.0886 30.3669 83.1717Z" fill="black"/>
          </svg>
          div 產品連結
      form(action="https://proxy-web.service5322.workers.dev" method="post" target="my_iframe")
        input(type="text" name="targetUrl" value="https://bgmotion.my.canva.site/flower")
        input(type="text" name="headers" :value="value")
        input(type="submit" value="Do Stuff!")
      <!-- when the form is submitted, the server response will appear in this iframe -->
      div.iframe-area
        iframe.iframe(name="my_iframe" :src="`https://proxy.bgmotion.com.tw/${product.introLink}`")
</template>
<style lang="scss">
.project {
  padding: 70px 70px 10px 70px;
  letter-spacing: 1px;
  width: 100%;
  .project-center {
    padding: 30px 30px 30px 30px;
    width: 100%;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .title {
      display: flex;
      align-items: stretch;
      h1 {
        font-size: 20px;
        line-height: 34px;
        margin: 0;
        font-weight: 900;
        background-color: #000;
        color: #fff;
        padding: 2px 10px;
      }
    }
    .btn {
      color: black;
      text-decoration: none;
      border: solid 1px #000;
      padding: 5px 15px 5px 5px;
      border-radius: 30px;
      display: flex;
      gap: 10px;
    }
    .content{
      margin: 20px 0px;
    }
    form {
      display: none
    }
    .iframe{
      width: 100%;
      min-height: calc(100vh - 200px);
    }
  }
}
@media (max-width: 1200px) {
  .project{
    padding: 70px 0px 10px 0px;
  }
}
@media (max-width: 380px) {
  .project {
    .project-center {
      .title {
        h1 {
          padding: 0px 5px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  components: {
  },
  setup () {
    // const post = reactive({})
    const store = useStore()
    const route = useRoute()
    const id = route.params.projectId

    const value = JSON.stringify([
      {
        key: 'Content-Security-Policy',
        value: "frame-ancestors *; report-uri https://csp.canva.com/_cspreport?app=websites; base-uri 'self'; object-src 'none'; script-src 'report-sample' 'strict-dynamic' 'nonce-14f4740e-b1a0-47fa-8c75-b2149fa15c11' https://www.google.com/recaptcha/api.js;"
      }
    ])
    return {
      product: computed(() => store.state.products.find(product => product.name === id)),
      value
    }
  }
}
</script>
