<template lang="pug">
.app
  q-layout
    div.cursor
    div.cursor-outer
    LeftBar
    RightBar
    Header
    MobileHeader
    router-view(v-slot="{ Component, route }")
      transition(name="fade" mode="out-in")
        component(:is="Component" :key="route.path")
    Footer
</template>

<script>
// import { ref } from 'vue'
import LeftBar from './components/LeftBar.vue'
import RightBar from './components/RightBar.vue'
import Header from './components/Header.vue'
import MobileHeader from './components/MobileHeader.vue'
import Home from './views/Home.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'LayoutDefault',

  components: {
    LeftBar,
    RightBar,
    Header,
    MobileHeader,
    Home,
    Footer
  },
  mounted () {
    const cursor = document.querySelector('.cursor')
    const cursorOuter = document.querySelector('.cursor-outer')
    document.addEventListener('mousemove', e => {
      cursor.setAttribute('style', 'top: ' + (e.clientY - 15) + 'px; left: ' + (e.clientX - 15) + 'px;')
    })
    document.addEventListener('mousemove', e => {
      cursorOuter.setAttribute('style', 'top: ' + (e.clientY - 20) + 'px; left: ' + (e.clientX - 20) + 'px;')
    })
  },
  data () {
    return {
      leftDrawerOpen: false
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  html{
    font-size: 16px;
  }
  body{
    font-family: 'Noto Sans HK', sans-serif !important;
    font-weight: 900;
    width: 100%;
    cursor: none;
  }
  .q-layout{
    width: 100%;
    // height: 100vh;
    margin: 0;
    padding: 0;
    height: calc(var(--vh, 1vh) * 100);
  }
  .cursor{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: rgba(248, 133, 39, 0.7);
    position: fixed;
    // transition-duration: 150ms;
    // transition-timing-function: ease-out;
    z-index: 9999;
    pointer-events: none;
  }
  .cursor-outer{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: rgba(248, 133, 39, 0.3);
    position: fixed;
    transition-duration: 100ms;
    transition-timing-function: ease-out;
    z-index: 9998;
    pointer-events: none;
  }
  .fade-enter-active, .fade-leave-active{
    transition: .3s;
  }
  .fade-enter-from, .fade-leave-to{
    opacity: 0;
  }
@media (max-width: 480px){
  .cursor, .cursor-outer{
    display: none;
  }
}
</style>
