<template lang="pug">
#web-page
  .web-page
    .web-center
      .title
        h2.ch 網頁設計
        h2.en Web Design
      .intro
        .icon
          img(src="../assets/arrow2.svg")
        p 有別於實體門市，突破時間與空間限制，將品牌調性融入於網站設計之中，讓企業品牌及商品隨時隨地於目標客群中曝光及轉換。
      .block#custom
        .name
          h4.ch 『 客製化網站 』
          h4.en Get The Website What You Want !
        .items
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web1.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 需求分析
              .item-intro
                p 依照業者所期望的網站呈現風格、功能需求以及企業品牌調性進行網站製作評估，討論網站最佳實現方式及使用體驗
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web2.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 架構規劃
              .item-intro
                p 規劃網站設計製作所需的素材、網頁資訊動線、以及版面的基本設計配置
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web3.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 客製化版面設計
              .item-intro
                p 將網站具體呈現風格調性以設計稿的模式提交給業者，並確認細項調整
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web4.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 動態設計
              .item-intro
                p 評估加入動態特效，提升網站吸睛度，加深品牌印象
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web5.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 響應式設計
              .item-intro
                p 網站適應瀏覽裝置變化，電腦、手機、平板皆可正常瀏覽使用
      .block#module
        .name
          h4.ch 『 模組網站 』
          h4.en Get The Website Which Is Suitable !
        .items
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web6.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 模組選擇與配置
              .item-intro
                p 依照需求選擇適合自家品牌的網頁版面及功能模組，進行組合搭配
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web7.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 細節與配置微調
              .item-intro
                p 針對素材(圖片及文案)進行討論調整 ; 版面在可更動範圍內進行配置微調
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/web5.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 響應式設計
              .item-intro
                p 網站適應瀏覽裝置變化，電腦、手機、平板皆可正常瀏覽使用
</template>
<style lang="scss" scoped>
.web-page{
  width: 100%;
  background-color: #fff;
  padding: 70px 70px 10px 70px;
  // border-bottom: solid 2px #000;
  .web-center{
    padding: 30px 30px 0px 30px;
    .title{
      display: flex;
      // border: solid 3px;
      margin-bottom: 30px;
      h2{
        font-size: 28px;
        line-height: 28px;
        margin: 0px;
        font-weight: 900 !important;
      }
      .ch{
        margin-right: 10px;
        letter-spacing: 1px;
      }
    }
    .intro{
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      .icon{
        width: 30px;
        display: flex;
        margin-right: 10px;
      }
      p{
        font-size: 16px;
        margin: 0px;
        border: solid 3px #000;
        flex: 1;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #f1f1f1;
        letter-spacing: 1px;
      }
    }
    #custom{
      margin-bottom: 80px
    }
    .block{
      // border: solid 3px;
      .name{
        display: flex;
        margin-bottom: 4vh;
        h4{
          font-size: 20px;
          margin: 0px;
        }
        .ch{
          background-color: #000;
          padding: 0px 10px;
          margin-right: 20px;
          color: #fff;
          letter-spacing: 1px;
          border-radius: 3px;
          font-weight: 900 !important;
        }
        .en{
          transform: skewX(-5deg);
          color: #fff;
          -webkit-text-stroke: 1.5px #333;
          font-size: 24px;
          font-weight: 900 !important;
        }
      }
      .items{
        // border: solid 3px;
        .item{
          // border: solid 3px;
          display: flex;
          // justify-content: space-between;
          margin-bottom: 4vh;
          .text{
            // border: solid 3px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 55vw;
            // margin-right: 8%;
            .item-name{
              // border: solid 3px;
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 10px;
              img{
                height: 30px;
                margin-right: 20px;
              }
              h6{
                margin: 0px;
                letter-spacing: 1px;
                font-weight: 900 !important;
              }
            }
            .item-intro{
              // border: solid 3px;
              p{
                font-size: 18px;
                letter-spacing: 1px;
                line-height: 28px;
              }
            }
          }
          .box{
            height: 15vw;
            width: 20vw;
            min-height: 250px;
            min-width: 350px;
            position: relative;
            border: solid 2px #000;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: #f1f1f1;
            z-index: 99;
            margin-right: 40px;
            background-image: radial-gradient(#5a5a5a 0.5px, #f4f4f4 0.5px);
            background-size: 10px 10px;
            img{
              height: 80%;
            }
            .dot{
              width: 8px;
              height: 8px;
              position: absolute;
              background-color: #fff;
              border: solid 2px #000;
            }
            .dot1{
              left: -5px;
              top: -5px;
            }
            .dot2{
              left: calc(50% - 5px);
              top: -5px;
            }
            .dot3{
              right: -5px;
              top: -5px;
            }
            .dot4{
              left: -5px;
              top: calc(50% - 5px);
            }
            .dot5{
              right: -5px;
              top: calc(50% - 5px);
            }
            .dot6{
              left: -5px;
              bottom: -5px;
            }
            .dot7{
              left: calc(50% - 5px);
              bottom: -5px;
            }
            .dot8{
              right: -5px;
              bottom: -5px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .web-page{
    padding: 70px 0px 10px 0px;
  }
}
@media (max-width: 780px) {
  .web-page{
    .web-center{
      .title{
        display: flex;
        align-items: flex-end;
        .en{
          font-size: 16px;
          line-height: 16px;
        }
      }
      .block{
        .name{
          flex-direction: column;
          align-items: flex-start;
          .ch{
            // display: inline-block;
            white-space: nowrap;
            margin-bottom: 10px;
            width: 100%;
            text-align: center;
          }
        }
        .items{
          .item{
            flex-direction: column;
            .box{
              margin-bottom: 20px;
              width: 100%;
              min-width: 240px;
            }
            .text{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 380px) {
  .web-page{
    .web-center{
      .title{
        // flex-direction: column;
        // align-items: flex-start;
        // margin-bottom: 10px;
        // .ch{
        //   font-size: 26px;
        //   width: 100%;
        //   text-align: center;
        //   margin-right: 0px;
        // }
        .en{
          display: none;
        }
      }
      .intro{
        .icon{
          display: none;
        }
        // flex-direction: column;
        // .icon{
        //   transform: rotate(90deg);
        //   margin-right: 0;
        //   margin-bottom: 10px;
        // }
      }
      // .block{
      //   .name{
      //     .en{
      //       font-size: 17px;
      //       -webkit-text-stroke: 1px #333;
      //       line-height: 20px;
      //       margin-top: 10px;
      //     }
      //   }
      // }
    }
  }
}
</style>
<script>
export default {
  components: {
  },
  setup () {
    return {
    }
  }
}
</script>
