import axios from 'axios'

const reqObj = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 4000
})

/** 包裝、處理 axios 請求 */
function handleReq (p) {
  return new Promise((resolve, reject) => {
    p
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export function postForm (formData) {
  return handleReq(reqObj.post('/v1/contact-us', formData))
}
