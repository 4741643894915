<template lang="pug">
div.header
  .left
    router-link(to="/")
      img(src="../assets/cool-icon.svg")
      .brand Cool_stuff
  .center
    router-link(to="/web")
      .header-link
        .ch 網頁
        .en web
    router-link(to="/system")
      .header-link
        .ch 系統
        .en system
    router-link(to="/contact")
      .header-link
        .ch 聯絡
        .en contact
    router-link(to="/project-list")
      .header-link
        .ch 酷の產品
        .en product
    router-link(to="/blog")
      .header-link
        .ch 部落格
        .en blog
  .right
    a(href="https://lin.ee/PBYTCkD")
      img(src="../assets/line.svg")
    a(href="mailto:service@coolstuff-studio.com")
      img(src="../assets/mail.svg")
</template>
<style lang="scss">
.header {
  position: fixed;
  display: flex;
  padding: 15px;
  height: 60px;
  width: calc(100% - 10px);
  border-bottom: solid 2px #000;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 9997;

  .left {
    display: flex;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      img {
        height: 30px;
      }

      .brand {
        color: #000;
        margin-left: 10px;
        font-size: 20px
      }
    }
  }

  .right {
    display: flex;

    a {
      display: flex;
      align-items: center;
      border: solid 2px #000;
      border-radius: 5px;
      padding: 0px 3px;
      margin: 0px 4px;

      img {
        height: 30px;
      }
    }
  }

  .center {
    display: flex;
    margin-right: 60px;

    a {
      text-decoration: none;
      color: #000;
      margin: 0px 10px;

      .header-link {
        display: flex;
        align-items: center;

        .ch {
          font-size: 16px;
          letter-spacing: 2px;
        }

        .en {
          border: solid 2px #000;
          padding: 0px 10px;
          margin: 0px 5px;
          border-radius: 5px;
          transition-duration: .5s;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .header {
    width: 100%;
  }
}

@media (max-width: 1140px) {
  .header {
    display: none;
  }
}
</style>

<script>
export default {
  name: 'Header'
}
</script>
