<template lang="pug">
#mobile-main
  CoolBar(v-bind:parent-open="isOpen" @closeList="closeList")
  //- q-drawer(v-model="drawerLeft" :breakpoint="700" elevated :width="$q.screen.width")
  //-   .close(@click="drawerLeft = !drawerLeft") X
  //-   q-scroll-area.fit
  //-     .item
  //-       .name
  //-         span.icon
  //-           img(src="../../assets/game.svg")
  //-         span 小遊戲 Games
  //-     .item
  //-       .name
  //-         span.icon
  //-           img(src="../../assets/effect.svg")
  //-         span 特效 Effects
  q-page-container.mobile-main
    .main-center
      #mobile-p5-container
      .top-bar
        .left
          .left-inner
            .four
              img(src="../../assets/cool-four.svg")
            .cool-item
              .arrow
                img(src="../../assets/arrow4.svg")
              .menu-btn(@click="open ()")
                img.menu(src="../../assets/menu.svg")
        .right
          .outer
            .bg
            .calendar
              .month {{ getCalendar.month[0].name }}
              .date {{ getCalendar.date }}
      .center-bar
        .top
          .outer
            h1 網頁設計 ＆ 系統開發
        .center
          //- h1 網頁設計 ＆ 系統開發
          img(src="../../assets/cool-center.svg")
          //- .service-btn(@click="scroll()")
          //-   .bg
          //-   .btn 服務項目
        .bottom
          .service-btn(@click="scroll()")
            .bg
            .btn 服務項目
          h2 Web Design / System Development
      .bottom-bar
    MobileMarquee
    //- .mobile-marquee-block
    //-   .mobile-marquee
    //-     p 抱歉了錢錢，但我真的需要這個酷東西。Create a new Possibility and Keep it Cool.
</template>
<style lang="scss">
#mobile-main{
  display: none;
}
#mobile-p5-container {
  width: calc(100vw - 60px);
  // height: calc(100vh - 60px);
  height: calc(var(--vh, 1vh) * 100 - 60px);
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: solid 2px #000;
  display: none;
}
// #mobile-main .q-drawer--left{
//   padding: 80px 20px;
//   box-shadow: 3px -3px 4px 1px  rgba(0, 0, 0, 0.1);
//   border: solid 2px #000;
//   .q-drawer__content{
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end;
//     .close{
//       background-color: #000;
//       display: inline-flex;
//       color: #fff;
//       padding: 5px 20px;
//       margin-bottom: 20px;
//       border-radius: 3px;
//       font-size: 20px;
//     }
//   }
//   .q-layout__shadow{
//     display: none;
//     &:after{
//       top: 10px !important;
//     }
//   }
//   .item{
//     border: solid 2px;
//     padding: 5px 18px;
//     margin-bottom: 20px;
//     border: solid 2px #eee;
//     background-color: #000;
//     border-radius: 10px;
//     &:hover{
//       .name{
//         letter-spacing: 2px;
//       }
//     }
//     .name{
//       margin: 0;
//       font-size: 16px;
//       line-height: 16px;
//       color: #fff;
//       letter-spacing: 1px;
//       transition-duration: .5s;
//       display: flex;
//       align-items: center;
//       .icon{
//         margin-right: 10px;
//         width: 24px;
//         transform: translateY(1px);
//       }
//     }
//   }
// }
.mobile-main{
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #f4f4f4;
  padding: 60px 0px 0px 0px !important;
  border-bottom: solid 2px #000;
  position: relative;
  overflow: hidden;
  .main-center{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 0px 20px;
    position: relative;
    .top-bar{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 10%;
      z-index: 99;
      .left{
        display: flex;
        justify-content: flex-start;
        height: 100%;
        .left-inner{
          display: flex;
          flex-direction: column;
          width: 100%;
          .four{
            width: 100%;
            z-index: 99;
            // border: solid 3px red;
            img{
              height: 4vh;
            }
          }
          .cool-item{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            width: 100%;
            .arrow{
              display: flex;
              height: 100%;
              align-items: center;
              margin-right: 10px;
              // border: solid 3px red;
              img{
                height: 4vh;
              }
            }
            .menu-btn{
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #000;
              border-radius: 2px;
              height: 3.5vh;
              width: 100%;
              box-sizing: border-box;
              .menu{
                height: 70%;
              }
            }
          }
        }
      }
      .right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .outer{
          height: 8vh;
          position: relative;
          // transform: translateX(calc(40px + 20px));
          .bg{
            position: absolute;
            border-radius: 2px;
            width: 100%;
            height: 100%;
            background-color: #000;
            transform: translate(5px, 5px);
          }
          .calendar{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px 10px;
            height: 100%;
            border: solid 2px #000;
            border-radius: 2px;
            position: relative;
            background-color: #fff;
            .month{
              font-size: 2vh;
              line-height: 2.7vh;
            }
            .date{
              font-size: 4vh;
              line-height: 4.3vh;
            }
          }
        }
      }
    }
    .center-bar{
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 99;
      box-sizing: border-box;
      .top{
        width: 100%;
        display: flex;
        justify-content: center;
        .outer{
          background-color: #f4f4f4;
          padding: 7px 35px;
          transform: skew(-10deg);
          border: solid 3px #000;
          border-radius: 5px;
          display: inline-block;
          h1{
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            margin: 0;
            display: inline-block;
            font-weight: 900;
          }
        }
      }
      .center{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 0px;
        img{
          width: 90%;
          z-index: 9;
          max-height: 200px;
        }
      }
      .bottom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 99;
        .service-btn{
          position: relative;
          z-index: 99;
          // margin-bottom: 25px;
          // margin-top: 50px;
          &:hover{
            .btn{
              transform: translateY(-3px);
            }
          }
          .btn{
            font-size: 16px;
            letter-spacing: 5px;
            border: solid 3px #fff;
            padding: 7px 100px;
            border-radius: 30px;
            background-color: #000;
            transition-duration: .5s;
            transform: translateY(-8px);
            color: #fff;
          }
          .bg{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000;
            bottom: 0px;
            z-index: -9;
            border-radius: 20px;
          }
        }
        h2{
          margin: 0px;
          margin-top: 10px;
          font-size: 18px;
          line-height: 24px;
          transform: skewX(-10deg);
          font-weight: 900;
        }
      }
    }
    .bottom-bar{
      height: calc(20% - 20px);
      width: 100%;
      z-index: 999;
      margin-top: 20px;
    }
  }

  .mobile-marquee-block{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    // bottom: 4vh;
    // right: calc(20px + 60px);
    background-color: #000;
    z-index: 98;
    .mobile-marquee{
      position: relative;
      padding: 10px 20px;
      height: 80px;
      overflow: hidden;
      display: flex;
      align-items: center;
      p{
        font-size: 18px;
        letter-spacing: 2px;
        color: #fff;
        margin: 0;
        position: absolute;
        right: -380px;
        white-space: nowrap;
      }
    }
  }
}
@media (max-width: 900px) {
  #mobile-main{
    display: block;
  }
}
@media (max-width: 380px) {
  #mobile-main{
    .main-center{
      .center-bar{
        .top{
          .outer{
            padding: 5px 35px;
          }
          h1{
            font-size: 16px;
          }
        }
        .center{
          padding: 15px 0px;
        }
        .bottom{
          .service-btn{
            .btn{
              white-space: nowrap;
              padding: 7px 80px;
              font-size: 14px;
            }
          }
          h2{
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
<script>
import CoolBar from '../CoolBar.vue'
import MobileMarquee from '../MobileMarquee'
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import createP5 from '../../p5/p5-mobile.js'
export default {
  components: {
    MobileMarquee,
    CoolBar
  },
  setup () {
    // const marqueeText = ''
    gsap.registerPlugin(ScrollToPlugin)
    // const drawerLeft = ref(false)
    const calendar = [
      {
        index: 0,
        name: 'Jan.'
      },
      {
        index: 1,
        name: 'Feb.'
      },
      {
        index: 2,
        name: 'Mar.'
      },
      {
        index: 3,
        name: 'Apr.'
      },
      {
        index: 4,
        name: 'May.'
      },
      {
        index: 5,
        name: 'Jun.'
      },
      {
        index: 6,
        name: 'Jul.'
      },
      {
        index: 7,
        name: 'Aug.'
      },
      {
        index: 8,
        name: 'Sep.'
      },
      {
        index: 9,
        name: 'Oct.'
      },
      {
        index: 10,
        name: 'Nov.'
      },
      {
        index: 11,
        name: 'Dec.'
      }
    ]
    const getCalendar = computed(() => {
      const e = new Date()
      const m = e.getMonth()
      const date = e.getDate()
      const month = calendar.filter(e => e.index === m)
      return {
        month,
        date
      }
    })

    function getVh () {
      const vh = window.innerHeight * 0.01
      document.querySelector('.mobile-main').style.setProperty('--vh', `${vh}px`)
      document.querySelector('#mobile-p5-container').style.setProperty('--vh', `${vh}px`)
    }

    onBeforeUnmount(() => {
    })
    onMounted(() => {
      createP5('mobile-p5-container')
      // NOTE: Use p5 as an instance mode
      getVh()
    })

    const isOpen = ref(false)
    function closeList (data) {
      isOpen.value = data
    }
    return {
      // drawerLeft,
      getCalendar,
      scroll () {
        gsap.to(window, { duration: 1, scrollTo: '#service' })
      },
      isOpen,
      open () {
        console.log('hello')
        isOpen.value = !isOpen.value
      },
      closeList
    }
  }
}
</script>
