<template lang="pug">
div.left-bar
  p Cool Stuff Studio©2021 Copyright.
</template>
<style lang="scss" scoped>
  .left-bar{
    position: fixed;
    width: 60px;
    height: 100vh;
    top: 0;
    left: 0;
    border-right: solid 2px #000000;
    background-color: #fff;
    z-index: 9996;
    p{
      transform: translate(-50%, -50%) rotate(90deg);
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vh;
      text-align: center;
      font-size: 18px;
    }
  }
  @media (max-width: 1200px){
    .left-bar{
      display: none;
    }
  }
</style>

<script>
export default {
  name: 'LeftBar',
  data () {
    return {
    }
  },
  mounted () {
  }
}
</script>
