<template lang="pug">
#system-page
  .system-page
    .system-center
      .title
        h2.ch 系統開發
        h2.en System Development
      .intro
        .icon
          img(src="../assets/arrow2.svg")
        p 重視軟體開發的服務設計，依照客戶熟悉的工作流程進行設計調整，透過流程自動化降低人工操作成本，在最熟悉的工作流程中體驗數位化的服務升級體驗。
      .block#custom
        .name
          h4.ch 『 應用型系統規劃 』
          h4.en Get The System What You Need !
        .items
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/system1.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 應用探討
              .item-intro
                p 了解客戶原本工作流程，進行工作流程拆解
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/system2.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 痛點分析
              .item-intro
                p 針對一直無法改善的問題進行痛點分析討論，優化原有的工作流程
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/system3.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 系統規劃
              .item-intro
                p 導入服務設計，進行專屬的自動化系統流程調整，確認規劃內容後進行開發
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/system4.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 敏捷開發進度追蹤
              .item-intro
                p 以兩週至四週的週期為一個開發衝刺，與客戶定期討論系統是否符合需求，在一定程度內進行調整
          .item
            .box
              .dot.dot1
              .dot.dot2
              .dot.dot3
              .dot.dot4
              .dot.dot5
              .dot.dot6
              .dot.dot7
              .dot.dot8
              img(src="../assets/system5.svg")
            .text
              .item-name
                img(src="../assets/arrow3.svg")
                h6 客製化優化調整
              .item-intro
                p 交付系統後仍可提供客製優化的調整彈性，依照客戶使用體驗進行系統調整
</template>
<style lang="scss" scoped>
.system-page{
  width: 100%;
  background-color: #fff;
  padding: 70px 70px 0px 70px;
  // border-bottom: solid 2px #000;
  .system-center{
    padding: 30px 30px 0px 30px;
    .title{
      display: flex;
      // border: solid 3px;
      margin-bottom: 30px;
      h2{
        font-size: 28px;
        line-height: 28px;
        margin: 0px;
        font-weight: 900 !important;
      }
      .ch{
        margin-right: 10px;
        letter-spacing: 1px;
      }
    }
    .intro{
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      .icon{
        width: 30px;
        display: flex;
        margin-right: 10px;
      }
      p{
        font-size: 16px;
        margin: 0px;
        border: solid 3px #000;
        flex: 1;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #f1f1f1;
        letter-spacing: 1px;
      }
    }
    #custom{
      margin-bottom: 40px
    }
    .block{
      // border: solid 3px;
      .name{
        display: flex;
        margin-bottom: 4vh;
        h4{
          font-size: 20px;
          margin: 0px;
        }
        .ch{
          background-color: #000;
          padding: 0px 10px;
          margin-right: 20px;
          color: #fff;
          letter-spacing: 1px;
          border-radius: 3px;
          font-weight: 900 !important;
        }
        .en{
          transform: skewX(-5deg);
          color: #fff;
          -webkit-text-stroke: 1.5px #333;
          font-size: 24px;
          font-weight: 900 !important;
        }
      }
      .items{
        // border: solid 3px;
        .item{
          // border: solid 3px;
          display: flex;
          // justify-content: space-between;
          margin-bottom: 4vh;
          .text{
            // border: solid 3px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 55vw;
            // margin-right: 8%;
            .item-name{
              // border: solid 3px;
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 10px;
              img{
                height: 30px;
                margin-right: 20px;
              }
              h6{
                margin: 0px;
                letter-spacing: 1px;
                font-weight: 900 !important;
              }
            }
            .item-intro{
              // border: solid 3px;
              p{
                font-size: 18px;
                letter-spacing: 1px;
                line-height: 28px;
              }
            }
          }
          .box{
            height: 15vw;
            width: 20vw;
            min-height: 250px;
            min-width: 350px;
            position: relative;
            border: solid 2px #000;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: #f1f1f1;
            z-index: 99;
            margin-right: 40px;
            background-image: radial-gradient(#5a5a5a 0.5px, #f4f4f4 0.5px);
            background-size: 10px 10px;
            img{
              height: 80%;
            }
            .dot{
              width: 8px;
              height: 8px;
              position: absolute;
              background-color: #fff;
              border: solid 2px #000;
            }
            .dot1{
              left: -5px;
              top: -5px;
            }
            .dot2{
              left: calc(50% - 5px);
              top: -5px;
            }
            .dot3{
              right: -5px;
              top: -5px;
            }
            .dot4{
              left: -5px;
              top: calc(50% - 5px);
            }
            .dot5{
              right: -5px;
              top: calc(50% - 5px);
            }
            .dot6{
              left: -5px;
              bottom: -5px;
            }
            .dot7{
              left: calc(50% - 5px);
              bottom: -5px;
            }
            .dot8{
              right: -5px;
              bottom: -5px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .system-page{
    padding: 70px 0px 10px 0px;
  }
}
@media (max-width: 780px) {
  .system-page{
    .system-center{
      .title{
        display: flex;
        align-items: flex-end;
        .en{
          font-size: 16px;
          line-height: 16px;
        }
      }
      .block{
        .name{
          flex-direction: column;
          align-items: flex-start;
          .ch{
            // display: inline-block;
            white-space: nowrap;
            margin-bottom: 10px;
            width: 100%;
            text-align: center;
          }
        }
        .items{
          .item{
            flex-direction: column;
            .box{
              margin-bottom: 20px;
              width: 100%;
              min-width: 240px;
            }
            .text{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 380px) {
  .system-page{
    .system-center{
      .title{
        // flex-direction: column;
        // align-items: flex-start;
        // margin-bottom: 10px;
        // .ch{
        //   font-size: 26px;
        //   width: 100%;
        //   text-align: center;
        //   margin-right: 0px;
        // }
        .en{
          display: none;
        }
      }
      .intro{
        .icon{
          display: none;
        }
        // flex-direction: column;
        // .icon{
        //   transform: rotate(90deg);
        //   margin-right: 0;
        //   margin-bottom: 10px;
        // }
      }
      // .block{
      //   .name{
      //     .en{
      //       font-size: 17px;
      //       -webkit-text-stroke: 1px #333;
      //       line-height: 20px;
      //       margin-top: 10px;
      //     }
      //   }
      // }
    }
  }
}
</style>
<script>
export default {
  components: {
  },
  setup () {
    return {
    }
  }
}
</script>
