import { render } from "./BlogPage.vue?vue&type=template&id=71f0f475&lang=pug"
import script from "./BlogPage.vue?vue&type=script&lang=js"
export * from "./BlogPage.vue?vue&type=script&lang=js"

import "./BlogPage.vue?vue&type=style&index=0&id=71f0f475&lang=scss"
script.render = render

export default script
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QResponsive});
