import { createStore } from 'vuex'

export default createStore({
  state: {
    posts: [
      {
        title: '淺談酷東西對於『 網站設計 』及『 系統開發 』的區別',
        date: '2022/5/19',
        summary: '網站設計及系統開發如何區別是我們常常會被提問的問題之一，但其實我們可以就這兩個類別所能達到的功能及效益來討論兩者之間的差異性。',
        url: 'difference-between-web-design-and-system-development',
        postId: 0,
        imgSrc: './images/post1.jpg',
        content: `<img src="/images/post1.jpg" alt="淺談網網站設計及系統開發的區別">
          <h4>" 網站設計 " 及 " 系統開發 " 如何區別是我們常常會被提問的問題之一，但其實我們可以就這兩個類別所能達到的功能及效益來討論兩者之間的差異性。</h4>
          <hr>
          <h2>網站設計</h2>
          <p>針對品牌及企業做客製化的展示型規劃製作，主要著重的項目會在於觸及使用者時所帶給使用者的品牌印象，以及最終是否成功轉換。</p>
          <h4>◎ 網站設計需要考量以下幾點要素：</h4>
          <ol>
            <li>版面及內容配置是否符合企業的形象？ </li>
            <li>使用者體驗是否流暢？ </li>
            <li>到達目標頁面的轉換率高低？ </li>
          </ol>
          <h4>◎ 達到以上目標需要擁有在網站設計的基本專業技能如下：</h4>
          <ol>
            <li>彈性的網頁切版能力 : 足以客製化出符合企業期望的網站視覺;</li>
            <li>RWD響應式設計 : 在行動裝置瀏覽網站如此高的時代，已經成為不可或缺的必要配置;</li>
            <li>網站架構規劃及動線討論 : 在得到業主所整理的需求及素材之後，整理規劃出一套架構規劃，再與業者溝通討論是否符合預期的使用者流程體驗，並針對不符合的項目進行調整;</li>
          </ol>
          <hr>
          <h2>系統開發</h2>
          <p>系統開發著重的要點在於功能應用端，相較於網頁設計，系統開發有更多的應用需求，並且依據業者需求及應用情境主要分為兩個面向。</p>
          <h4>◎ 系統開發需要具備以下幾點要素：</h4>
          <ol>
            <li>為提高任務的效率及便利性，針對已知的業務邏輯進行探討，找出作業流程上的痛點。</li>
            <li>企業為拓展商業模式需求所開發的應用服務，針對服務不同角度的使用者設計便捷友善的操作體驗。</li>
          </ol>
          <h4>◎ 達到以上目標需要擁有在系統開發的基本專業技能如下：</h4>
          <ol>
            <li>應用型系統程式開發經驗</li>
            <li>應用功能串接整合</li>
            <li>痛點分析及敏捷開發迭代產品</li>
            <li>系統架構及業務邏輯應用規劃</li>
          </ol>`
      }
    ],
    products: [
      {
        name: '報價高手',
        mainImage: './images/報價高手.png',
        productLink: 'https://lin.ee/OIkQkC0',
        introLink: 'https://bgmotion.my.canva.site/system'
      },
      {
        name: '背景好印',
        mainImage: './images/背景好印.png',
        productLink: 'https://lin.ee/OIkQkC0',
        introLink: 'https://bgmotion.my.canva.site/print'
      },
      {
        name: '派對工具人',
        mainImage: './images/派對工具人.png',
        productLink: 'https://lin.ee/OIkQkC0',
        introLink: 'https://bgmotion.my.canva.site/partyman'
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
