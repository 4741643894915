import { render } from "./WebTabs.vue?vue&type=template&id=e86ba51c&lang=pug"
import script from "./WebTabs.vue?vue&type=script&lang=js"
export * from "./WebTabs.vue?vue&type=script&lang=js"

import "./WebTabs.vue?vue&type=style&index=0&id=e86ba51c&lang=scss"
script.render = render

export default script
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSplitter,QTabs,QTab,QTabPanels,QTabPanel});
