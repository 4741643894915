import { render } from "./Main.vue?vue&type=template&id=b90edab4&lang=pug"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"

import "./Main.vue?vue&type=style&index=0&id=b90edab4&lang=scss"
script.render = render

export default script
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QScrollArea,QPageContainer,QLayout});
