<template lang="pug">
.right-bar(:class="{ openRequest: open }")
  .right-form(@click="openForm()")
    p 需
     br
     | 求
     br
     | 詢
     br
     | 問
  .form-block
    .outer
      p.form-title 酷東西製作需求單
      q-scroll-area(:thumb-style="thumbStyle" :bar-style="barStyle" :visible="visible")
        .form
          q-form(@submit="onSubmit")
            .single
              q-input(v-model="company" dark label-color="tiffany" label="公司名稱 *" hint="Your Company" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
            .together
              q-input(v-model="name" dark label-color="tiffany" label="聯絡人*" hint="Contact Person" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
              .radio-block
                .radio-btn
                  q-radio(v-model="gender" dark label-color="tiffany" val="man" label="先生")
                  q-radio(v-model="gender" dark val="women" label="小姐")
            .together
              q-input(v-model="mail" dark label-color="tiffany" label="E-mail *" hint="E-mail" lazy-rules :rules='mailRule')
              .space
              q-input(v-model="phone" dark label-color="tiffany" label="聯絡電話 *" hint="Number" lazy-rules :rules="phoneRule")
            .together
              .radio-block
                .radio-title 案件類型 *
                .radio-btn
                  q-radio(v-model="caseType" dark val="web" label="網頁設計" color="teal")
                  q-radio(v-model="caseType" dark val="system" label="系統開發")
                  q-radio(v-model="caseType" dark val="other" label="其他")
              .space
              q-input(v-model="intro" dark label-color="tiffany" label="公司產業別 *" hint="Intro" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
            .single
              q-input(v-model="web" dark label-color="tiffany" label="公司網站" hint="如原有公司網站，請填寫此欄位" :error="!webRule")
                template(v-slot:error) 請填入正確網址
            .together
              q-input(v-model="money" dark label-color="tiffany" label="預算範圍 *" hint="請填入預算" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
              .space
              q-input(v-model="url" dark label-color="tiffany" label="參考範例" hint="請填入網址" :error="!urlRule")
                template(v-slot:error) 請填入正確網址
            .single
              .textarea
                q-input(autogrow dark v-model="msg" label-color="v" label="需求簡述 *" hint="Message" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']")
            //- .together
            //-   q-toggle(v-model="accept" label="I accept the license and terms")
      div.btn-block
        q-btn.submit(label="提交 Submit" type="submit" color="teal-1")
        q-btn.q-ml-sm(label="Reset" type="reset" color="orange" flat="")
  .close
    .btn(@click="closeForm()")
      p X
</template>
<style lang="scss">
  .text-tiffany {
    color: rgba(223, 237, 232, 1) !important;
  }
  .bg-tiffany {
    color: rgba(223, 237, 232, .2) !important;
  }
  .right-bar.openRequest {
    left: 0 !important;
  }
  .right-bar{
    position: fixed;
    width: 110px;
    height: 100vh;
    top: 0;
    left: calc(100vw - 70px);
    transition-duration: 1s;
    z-index: 999;
    .close{
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      position: absolute;
      top: 60px;
      left: calc(100vw - 90px);
      .btn{
        width: 50px;
        height: 50px;
        background-color: rgba(223, 237, 232, .2);
        display: flex;
        border: solid 2px #dfede8;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        transition-duration: .5s;
        p{
          color: #dfede8;
          margin: 0;
          font-size: 30px;
        }
        &:hover{
          background-color: rgba(223, 237, 232, 1);
          p{
            color: #000;
          }
        }
      }
    }
    .form-block{
      width: calc(100vw - 60px);
      position: absolute;
      height: 100%;
      background-color: #000;
      top: 50%;
      transform: translateY(-50%);
      left: 60px;
      padding: 90px 100px 30px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .outer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .form-title{
          display: inline-block;
          color: rgba(223, 237, 232, 1);
          border-bottom: solid 1.5px rgba(223, 237, 232, 1);
          padding: 7px 0px;
          letter-spacing: 2px;
          font-size: 18px;
          // border-radius: 1px;
          margin: 0px 30px 20px 30px;
          // transform: skewX(-10deg);
        }
        .q-scrollarea{
          // background-color: #fff;
          border-radius: 5px;
          width: 80vw;
          height: 50vh;
          // max-width: 1000px;
          overflow: scroll;
          margin-bottom: 30px;
          .form{
            padding: 0px 30px;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // background-color: #f4f4f4;
            .q-form{
              width: 100%;
            }
            // .q-field__control{
            //   &::before{
            //     border-bottom: solid 1px #dfede8 !important;
            //   }
            // }
            .single{
              margin-bottom: 20px;
              .q-field__label{
                font-weight: 700;
              }
            }
            .together{
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .radio-block{
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 49%;
                .radio-btn{
                  margin: 0;
                  .q-radio__label{
                    color: rgba(223, 237, 232, 1);
                  }
                }
              }
              .q-field{
                width: 49%;
                .q-field__label{
                  font-weight: 700;
                }
              }
            }
          }
        }
        .btn-block{
          padding: 0px 30px;
          margin-top: 20px;
          .q-btn{
            font-weight: 900;
          }
          .submit{
            color: #000 !important;
          }
        }
      }
    }
    .right-form{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: flex;
      border: solid 3px #000;
      background-color: #000;
      text-decoration: none;
      padding: 30px 18px;
      border-radius: 1px;
      border-radius: 5px 0px 0px 5px;
      &:hover{
        p{
          color: #ff9540;
        }
      }
      p{
        color: #fff;
        text-align: center;
        font-size: 18px;
        margin: 0px;
        transition-duration: .5s;
      }
    }
  }
  @media (max-width: 1200px) {
    .right-bar{
      display: none;
    }
  }
</style>

<script>
import { useQuasar } from 'quasar'
import { ref, computed } from 'vue'
import validator from 'validator'
import { postForm } from '@/api/api'
export default {
  name: 'RightBar',
  setup () {
    const open = ref(false)
    const $q = useQuasar()
    const company = ref(null)
    const name = ref(null)
    const gender = ref(null)
    const mail = ref(null)
    const phone = ref(null)
    const intro = ref(null)
    const msg = ref(null)
    const caseType = ref(null)
    const money = ref(null)
    const web = ref('')
    const url = ref('')
    // const accept = ref(false)
    const mailRule = ref(
      [
        (val) => (val && val.length > 0) || 'Please type something',
        (val) => validator.isEmail(val) || '請輸入正確的電子郵件格式',
        (val) => val?.includes('gmail') || '信箱只能使用 Gmail',
        (val) => {
          const [, tailStr] = val.split('@')
          return !tailStr.includes('.tw') || '不可以使用 .tw 結尾的信箱'
        }
      ]
    )
    const phoneRule = ref(
      [
        (val) => (val && val.length > 0) || 'Please type something',
        (val) => {
          // const re = new RegExp('^09\\d{{8}}$')
          const normal = new RegExp('^\\(?(\\d{2})\\)?[\\s\\-]?(\\d{4})\\-?(\\d{4})$')
          const mobile = new RegExp('^09\\d{8}$')
          // const re = new RegExp('^(\\d{2,3}-?|\\(\\d{2,3}\\))\\d{3,4}-?\\d{4}|09\\d{2}(\\d{6}|-\\d{3}-\\d{3})')
          return (normal.test(val) || mobile.test(val)) || '請輸入正確的電話格式'
        }
      ]
    )

    return {
      color: ref('cyan'),
      company,
      name,
      gender,
      mail,
      phone,
      intro,
      msg,
      caseType,
      money,
      web,
      url,
      // accept,
      mailRule,
      phoneRule,
      webRule: computed(() => {
        if (web.value.length === 0) {
          return true
        } else {
          return validator.isURL(web.value)
        }
      }),
      urlRule: computed(() => {
        if (url.value.length === 0) {
          return true
        } else {
          return validator.isURL(url.value)
        }
      }),

      onSubmit () {
        const companyOriginalWebsite = web.value === '' ? undefined : web.value
        const referenceExample = url.value === '' ? undefined : url.value
        const collectData = {
          companyName: company.value,
          contactPerson: name.value,
          gender: gender.value,
          email: mail.value,
          phone: phone.value,
          caseType: caseType.value,
          companyIndustry: intro.value,
          companyOriginalWebsite,
          budgetRange: money.value,
          referenceExample,
          conceptDescription: msg.value
        }
        postForm(collectData).then((result) => {
          console.log('[ postForm ] result :', result)
          $q.notify({
            color: 'secondary',
            textColor: 'white',
            message: 'Cool Submit 提交成功',
            position: 'center'
          })
        }).catch((err) => {
          $q.notify({
            color: 'negative',
            textColor: 'white',
            message: 'Failed to submit 送出失敗',
            position: 'center'
          })
          console.log('[ postForm ] err :', err.response)
        })
      },
      onReset () {
        company.value = null
        name.value = null
        intro.value = null
        msg.value = null
        // accept.value = false
      },
      thumbStyle: {
        right: '6px',
        borderRadius: '5px',
        backgroundColor: '#dfede8',
        width: '8px',
        opacity: 0.75
      },
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#dfede8',
        width: '16px',
        opacity: 0.2
      },
      visible: ref(true),
      open,
      openForm () {
        open.value = true
      },
      closeForm () {
        open.value = false
      }
    }
  }
}
</script>
