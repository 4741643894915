<template lang="pug">
div.marquee-block
  div.marquee
    p 抱歉了錢錢，但我真的需要這個酷東西。Create a new Possibility and Keep it Cool.
</template>
<style lang="scss" scoped>
  .marquee-block{
    // position: fixed;
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 70px);
    transform: translateX(-30px);
    // bottom: 4vh;
    // right: calc(20px + 60px);
    background-color: #000;
    z-index: 98;
    border-radius: 5px;
    .marquee{
      position: relative;
      padding: 10px 20px;
      height: 60px;
      overflow: hidden;
      display: flex;
      align-items: center;
      p{
        font-size: 18px;
        letter-spacing: 2px;
        color: #fff;
        margin: 0;
        position: absolute;
        right: -380px;
        white-space: nowrap;
      }
    }
  }
  // @media (max-width: 1100px){
  //   .marquee-block{
  //     top: 76px;
  //     bottom: auto;
  //     .marquee{
  //       padding: 10px 20px;
  //       height: 40px;
  //       p{
  //         font-size: 14px;
  //         right: -380px;
  //       }
  //     }
  //   }
  // }
  @media (max-width: 900px) {
    .marquee-block{
      display: none;
    }
  }
</style>

<script>
export default {
  name: 'Marquee',
  data () {
    return {
      marqueeEffect: '',
      marqueeText: ''
    }
  },
  mounted () {
    const that = this
    this.marqueeText = document.querySelector('.marquee p')
    const marqueeTextWidth = document.querySelector('.marquee p').offsetWidth
    function getMarqueeWidth () {
      return document.querySelector('.marquee').offsetWidth
    }
    let marqueeWidth = getMarqueeWidth()

    this.marqueeEffect = setInterval(marquee, 20)
    function marquee () {
      marqueeWidth -= 1
      that.marqueeText.style.left = marqueeWidth + 'px'
      if (marqueeWidth < marqueeTextWidth * -1) {
        marqueeWidth = getMarqueeWidth()
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.marqueeEffect)
  }
}
</script>
