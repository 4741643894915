<template lang="pug">
#product-list
  .product-list-page
    .center
      .title
        h2.ch 酷の產品
        h2.en Cool Product
      .intro
        .icon
          img(src="../assets/arrow2.svg")
        p 結合網頁/系統/APP/韌體的全能型系統公司，專案討論及追蹤客戶需求 ，以最專業的方式解決產業通點 ，在這個人力成本高進而壓縮到利潤的時代 ，為業主量身打造客製化系統，提升企業產能及管理成本
      #custom
        .box
          .video-container
            <video ref="video" autoplay muted loop playsinline webkit-playsinline>
              <source :src="specialData.mainVideo" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            a.cover-link(href="https://keeper.coolstuff-studio.com/" target="_blank")
          .label {{ specialData.name }}
          .link-area
            a.video-intro-link(href="https://keeper.coolstuff-studio.com/" target="_blank") 前往產品介紹
        .box(v-for="item in data" :ratio="3/4" @click.stop.prevent="routeTo(item.name)")
          q-img(:src="item.mainImage" )
          .label {{ item.name }}
          .link-area
            .intro-link(@click.stop.prevent="routeTo(item.name)") 前往產品介紹
            .product-link(@click.stop.prevent="linkTo(item.productLink)") 產品連結

</template>
<style lang="scss" scoped>
video {
  width: 100%;
  transform: translateY(3px);
}

.product-list-page {
  width: 100%;
  background-color: #fff;
  padding: 70px 70px 0px 70px;

  // border-bottom: solid 2px #000;
  .center {
    padding: 30px 30px 0px 30px;

    a {
      color: #000;
    }

    .title {
      display: flex;
      // border: solid 3px;
      margin-bottom: 30px;

      h2 {
        font-size: 28px;
        line-height: 28px;
        margin: 0px;
        font-weight: 900 !important;
      }

      .ch {
        margin-right: 10px;
        letter-spacing: 1px;
      }
    }

    .intro {
      display: flex;
      align-items: center;
      margin-bottom: 80px;

      .icon {
        width: 30px;
        display: flex;
        margin-right: 10px;
      }

      p {
        font-size: 16px;
        margin: 0px;
        border: solid 3px #000;
        flex: 1;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #f1f1f1;
        letter-spacing: 1px;
      }
    }

    #custom {
      margin-bottom: 40px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 80px 40px;
      width: 100%;
    }

    .box {
      width: calc(50% - 20px);
      border: solid 3px black;
      position: relative;
      border-radius: 5px;
      aspect-ratio: 7/4;
    }

    .video-container{
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .cover-link{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .q-img {
      min-width: 100%;
      min-height: 100%;

      :deep() .q-img__container {
        border-radius: 5px;
        overflow: hidden;
      }
    }

    .label {
      position: absolute;
      top: 0px;
      left: 20px;
      transform: translateY(-50%);
      width: auto !important;
      height: auto !important;
      background-color: #000;
      color: #fff;
      font-weight: 600;
      padding: 4px 15px;
      border-radius: 5px;
      font-size: 20px;
    }

    .link-area {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .video-intro-link{
      border: solid 2px #000;
      background-color: #fff;
      transform: translateX(15px) translateY(-50%) rotate(15deg);
      text-align: center;
      padding: 3px 20px;
      border-radius: 7px;
      position: absolute;
      white-space: nowrap;
      right: 0px;
      transition-duration: .5s;

      &:hover {
        transform: translateX(15px) translateY(-50%) rotate(0deg);
      }
    }

    .intro-link {
      border: solid 2px #000;
      background-color: #fff;
      transform: translateX(15px) translateY(-22%) rotate(5deg);
      text-align: center;
      padding: 3px 20px;
      border-radius: 7px;
      position: absolute;
      white-space: nowrap;
      right: 0px;
      transition-duration: .5s;

      &:hover {
        transform: translateX(15px) translateY(-22%) rotate(0deg);
      }
    }

    .product-link {
      border: solid 2px #000;
      background-color: #fff;
      transform: translateX(-25px) translateY(-110%) rotate(-5deg);
      text-align: center;
      padding: 3px 30px;
      border-radius: 7px;
      position: absolute;
      white-space: nowrap;
      right: 0px;
      transition-duration: .5s;

      &:hover {
        transform: translateX(-25px) translateY(-102%) rotate(0deg);
      }
    }
  }
}

@media (max-width: 1200px) {
  .product-list-page {
    padding: 70px 0px 10px 0px;
  }
}

@media (max-width: 780px) {
  .product-list-page {
    .center {
      .title {
        display: flex;
        align-items: flex-end;

        .en {
          font-size: 16px;
          line-height: 16px;
        }
      }

      .box {
        width: calc(100vw - 60px);
        // height: calc((100vw - 60px)/4*3);
      }
    }
  }
}

@media (max-width: 380px) {
  .product-list-page {
    .center {
      .title {
        // flex-direction: column;
        // align-items: flex-start;
        // margin-bottom: 10px;

        // .ch {
        //   font-size: 26px;
        //   width: 100%;
        //   text-align: center;
        //   margin-right: 0px;
        // }

        .en {
          display: none;
        }
      }

      .intro {
        .icon {
          display: none;
        }
      }

      .label {
        left: -15px;
        font-size: 16px;
      }

      .intro-link {
        font-size: 12px;
      }

      .product-link {
        font-size: 12px;
      }
    }
  }
}
</style>
<script>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const video = ref(null)
    function routeTo (id) {
      router.push(`/project/${id}`)
    }
    function linkTo (link) {
      window.open(link, '_blank')
    }

    const specialData = ref({
      name: '酷存助手',
      mainVideo: './酷存助手.mp4',
      productLink: 'https://coolstuff-backup.webflow.io/',
      introLink: 'https://coolstuff-backup.webflow.io/'
    })

    onMounted(() => {
      video.value.play()
    })

    return {
      data: computed(() => store.state.products),
      routeTo,
      linkTo,
      specialData,
      video
    }
  }
}
</script>
