<template lang="pug">
q-splitter(v-model="splitterModel" unit="px")
  template(v-slot:before)
    q-tabs(v-model="tab" vertical)
      q-tab(name="應用型系統開發" label="應用型系統開發")
  template(v-slot:after)
    q-tab-panels(v-model="tab" animated vertical transition-prev="jump-up" transition-next="jump-up")
      q-tab-panel(name="應用型系統開發")
        .text-h5.q-mb-md 應用型系統開發
        p ▋ &ensp;應用探討
        p ▋ &ensp;痛點分析
        p ▋ &ensp;系統規劃
        p ▋ &ensp;敏捷開發進度追蹤
        p ▋ &ensp;客製化優化調整
        router-link(to="/system")
          img(src="../assets/arrow4.svg")
          p 更多應用系統開發內容
</template>
<style lang="scss">
.q-tab__label{
  font-weight: 900 !important;
}
.q-splitter{
  .text-h5{
    font-weight: 900 !important;
  }
}
@media (max-width: 1200px) {
  .q-splitter{
    .q-tab__indicator{
      &::after{
        transform: translateY(-50%) rotate(90deg) !important;
      }
    }
  }
}
</style>
<script>
import { ref } from 'vue'
export default {
  components: {
  },
  setup () {
    return {
      tab: ref('應用型系統開發'),
      splitterModel: ref(250)
    }
  }
}
</script>
