<template lang="pug">
q-splitter(v-model="splitterModel" unit="px")
  template(v-slot:before)
    q-tabs(v-model="tab" vertical)
      q-tab(name="客製化網頁設計建構" label="客製化網頁設計建構" @click="scroll")
      q-tab(name="模組化網頁設計配置" label="模組化網頁設計配置" @click="scroll")
  template(v-slot:after)
    q-tab-panels(v-model="tab" animated  vertical transition-show="jump-up" transition-hide="jump-up")
      q-tab-panel(name="客製化網頁設計建構")
        .text-h5.q-mb-md 客製化網頁設計建構
        p ▋ &ensp;使用者介面設計
        p ▋ &ensp;使用者流程規劃
        p ▋ &ensp;響應式設計
        p ▋ &ensp;動態效果設計製作
        p ▋ &ensp;CMS內容管理系統
        router-link(to="/web")
          img(src="../assets/arrow4.svg")
          p 更多客製化網頁設計內容
      q-tab-panel(name="模組化網頁設計配置")
        .text-h5.q-mb-md 模組化網頁設計配置
        p ▋ &ensp;模組選擇與配置
        p ▋ &ensp;細節與配置微調
        p ▋ &ensp;響應式設計
        p ▋ &ensp;CMS內容管理系統
        router-link(to="/web")
          img(src="../assets/arrow4.svg")
          p 更多模組化網頁設計內容
</template>
<style lang="scss">
.q-tab__label{
  font-weight: 900 !important;
}
.q-splitter{
  .text-h5{
    font-weight: 900 !important;
  }
}
@media (max-width: 1200px) {
  .q-splitter{
    .q-tab__indicator{
      &::after{
        transform: translateY(-50%) rotate(90deg) !important;
      }
    }
  }
}
</style>
<script>
import { ref, onMounted } from 'vue'
export default {
  components: {
  },
  setup () {
    const scroller = ref(null)
    onMounted(() => {
      scroller.value = document.querySelector('.q-splitter__after')
    })
    const scroll = () => {
      scroller.value.scrollTop = 0
    }
    return {
      tab: ref('客製化網頁設計建構'),
      splitterModel: ref(250),
      scroll
    }
  }
}
</script>
